<template>
  <flat-pickr
    v-model="temp.value"
    class="form-control"
    :config="{
      mode: temp.option,
      dateFormat: temp.dateFormat,
      time_24hr: enableTime,
    }"
  />
</template>

<script>
import flatPickr from "vue-flatpickr-component";

export default {
  name: "date-picker",
  props: {
    dateObject: {
      required: true,
      type: Object,
    },
  },
  components: {
    flatPickr,
  },
  data() {
    return {
      temp: this.dateObject,
    };
  },
  computed: {
    enableTime() {
      return this.temp.dateFormat && this.temp.dateFormat.includes("H:i");
    },
  },
  emits: ["update"],
  watch: {
    temp(val) {
      this.$emit("update", val);
    },
  },
};
</script>