<template>
  <div class="app-calendar overflow-hidden border">
    <div class="row no-gutters">
      <!-- Sidebar -->
      <div
        class="
          col
          app-calendar-sidebar
          flex-grow-0
          overflow-hidden
          d-flex
          flex-column
        "
        :class="{ show: isCalendarOverlaySidebarActive }"
      >
        <calendar-sidebar
          :is-event-handler-sidebar-active.sync="isEventHandlerSidebarActive"
          :calendarOptions="eventGroups"
          :selectedCalendars="selectedEvents"
          v-if="sidebarReady"
        />
      </div>

      <!-- Calendar -->
      <div class="col position-relative">
        <div class="card shadow-none border-0 mb-0 rounded-0">
          <div class="card-body pb-0">
            <full-calendar
              ref="refCalendar"
              :options="calendarOptions"
              class="full-calendar"
            />
          </div>
        </div>
      </div>

      <!-- Sidebar Overlay -->
      <!-- <div
        class="body-content-overlay"
        :class="{'show': isCalendarOverlaySidebarActive}"
        @click="isCalendarOverlaySidebarActive = false"
      />
      <calendar-event-handler
        v-model="isEventHandlerSidebarActive"
        :event="event"
        :clear-event-data="clearEventData"
        @remove-event="removeEvent"
        @add-event="addEvent"
        @update-event="updateEvent"
      />
    </div> -->
    </div>
  </div>
</template>

<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";

import CalendarSidebar from "./calendar/calendar-sidebar/CalendarSidebar.vue";
import CalendarEventHandler from "./calendar/calendar-event-handler/CalendarEventHandler.vue";

export default {
  name: "common_report_calendar",
  components: {
    FullCalendar, // make the <FullCalendar> tag available
    CalendarSidebar,
    CalendarEventHandler,
  },
  props: {
    reportMasterId: { required: true, type: [Number, String] },
    calendarSettings: { required: true },
    calendarData: { required: true },
  },
  data() {
    return {
      isCalendarOverlaySidebarActive: false,
      isEventHandlerSidebarActive: false,
      calendarOptions: null,
      eventGroups: [],
      selectedEvents: [],
      sidebarReady: false,
    };
  },
  created() {
    if (
      this.calendarData.length > 0 &&
      this.calendarData[0]?.groupName != undefined
    ) {
      let tempGroups = this.calendarData.reduce((x, y) => {
        x[y.groupName] = [...(x[y.groupName] || []), y];
        return x;
      }, {});
      for (const [key, value] of Object.entries(tempGroups)) {
        this.selectedEvents.push(key);
        this.eventGroups.push({
          color: value[0].className,
          label: key,
        });
      }
    }
    this.calendarSettings.events = this.calendarData;
    this.calendarOptions = {
      ...this.calendarSettings,
      plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
      initialView: "dayGridMonth",
      headerToolbar: {
        start: "sidebarToggle, prev,next, title",
        end: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
      },
      customButtons: {
        sidebarToggle: {
          // --- This dummy text actual icon rendering is handled using SCSS ----- //
          text: "sidebar",
          click() {
            // eslint-disable-next-line no-use-before-define
            isCalendarOverlaySidebarActive.value =
              !isCalendarOverlaySidebarActive.value;
          },
        },
      },
      buttonText: {
        today: "Bugün",
        month: "Ay",
        week: "Hafta",
        day: "Gün",
        list: "Liste",
        listMonth: "Aylık Liste",
        listYear: "Yıllık Liste",
        listWeek: "Haftalık Liste",
        listDay: "Günlük Liste",
      },
    };
    this.sidebarReady = true;
    this.$emit("ready");
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
</style>
