<template>
  <div
    class="
      sidebar-wrapper
      d-flex
      justify-content-between
      flex-column flex-grow-1
    "
  >
    <div class="p-2">
      <div class="mt-3">
        <h5 class="app-label section-label mb-1">
          <span class="align-middle">İşlem Tipi</span>
        </h5>
        <b-form-checkbox v-model="checkAll" class="mb-1">
          Tümü
        </b-form-checkbox>
        <b-form-group>
          <b-form-checkbox-group
            v-model="tempselectedCalendars"
            name="event-filter"
            stacked
          >
            <b-form-checkbox
              v-for="item in calendarOptions"
              :key="item.label"
              name="event-filter"
              :value="item.label"
              class="mb-1"
              :class="`custom-control-${item.color}`"
            >
              {{ item.label }}
            </b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>
      </div>
    </div>
    <b-img :src="require('@/assets/images/pages/calendar-illustration.png')" />
  </div>
</template>

<script>
import {
  BButton,
  BFormGroup,
  BFormCheckboxGroup,
  BFormCheckbox,
  BImg,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BImg,
    BFormCheckbox,
    BFormGroup,
    BFormCheckboxGroup,
  },
  props: {
    isEventHandlerSidebarActive: {
      type: Boolean,
      require: true,
    },
    calendarOptions: {
      require: true,
    },
    selectedCalendars: {
      require: true,
    },
  },
  data() {
    return {
      tempselectedCalendars: [],
    };
  },
  created() {
    this.tempselectedCalendars = this.selectedCalendars;
  },
  computed: {
    checkAll: {
      get: function () {
        return (
          this.tempselectedCalendars.length ===
          this.calendarOptions.length
        );
      },
      set: function (val) {
        if (val) {
          this.tempselectedCalendars = this.calendarOptions.map(
            (i) => i.label
          );
        } else if (
          this.tempselectedCalendars.length ===
          this.calendarOptions.length
        ) {
          this.tempselectedCalendars = [];
        }
      },
    },
  },
  watch:{
    tempselectedCalendars(item){
      let tempAllEvents = this.$parent.calendarData;
      let tempItems = [];
      item.forEach(element => {
        let tempEvents = tempAllEvents.filter(x => x.groupName == element);
        tempItems = [...tempItems,...tempEvents];
      });
      this.$parent.calendarOptions.events = tempItems;  
    }
  }
};
</script>

<style>
</style>
