<template>
  <!-- #region Panel Ayarları -->
  <div class="dashboard-container" :class="{ open: show }">
    <!-- #region Panel Ayarları Aç/Kapat -->
    <b-link
      class="dashboard-toggle d-flex align-items-center justify-content-center"
      @click="show = !show"
    >
      <feather-icon icon="EditIcon" size="15" />
    </b-link>
    <!-- #endregion -->
    <validation-observer
      ref="check"
      v-slot="{ invalid }"
      class="dashboard-settings"
      tag="form"
    >
      <!-- #region Panel Adı -->
      <b-form-group
        :label="$t('dashboard_crud.name')"
        label-for="dashboard_title"
      >
        <validation-provider
          #default="{ errors }"
          :name="$t('dashboard_crud.name')"
          rules="required"
        >
          <b-form-input
            id="dashboard_title"
            v-model="title"
            :state="errors.length > 0 ? false : null"
            :placeholder="$t('dashboard_crud.name_placeholder')"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <!-- #endregion -->
      <!-- #region Aktif&Pasif -->
      <b-form-group :label="$t('dashboard_crud.state')">
        <b-form-checkbox
          :checked="!dashboardCrudIsPassive"
          switch
          inline
          @change="changeStatus()"
        >
          {{
            dashboardCrudIsPassive
              ? $t("dashboard_crud.passive")
              : $t("dashboard_crud.active")
          }}
        </b-form-checkbox>
      </b-form-group>
      <!-- #endregion -->
      <!-- #region Panel Açıklaması -->
      <b-form-group
        :label="$t('dashboard_crud.description')"
        label-for="dashboard_description"
      >
        <b-form-textarea
          type="textarea"
          id="dashboard_description"
          v-model="description"
          :placeholder="$t('dashboard_crud.description_placeholder')"
          rows="3"
          max-rows="4"
        />
      </b-form-group>
      <!-- #endregion -->
      <!-- #region Kaydet -->
      <b-button
        :disabled="invalid"
        variant="primary"
        class="save-button"
        @click="saveReport()"
      >
        {{ $t("dashboard_crud.save") }}
      </b-button>
      <!-- #endregion -->
    </validation-observer>
  </div>
  <!-- #endregion -->
</template>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/include";
.dark-layout {
  .dashboard-container {
    background: $theme-dark-body-bg;
    border-color: $theme-dark-border-color;
  }
}
.dashboard-container {
  width: 300px;
  padding: 0;
  z-index: 1052;
  position: fixed;
  top: 0;
  right: -300px;
  height: 100vh;
  background: $body-bg;
  height: calc(var(--vh, 1vh) * 100);
  transition: right 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
  backface-visibility: hidden;
  border-left: 1px solid $border-color;
  &.open {
    box-shadow: 0 15px 30px 0 rgba($theme-dark-border-color, 0.11),
      0 5px 15px 0 rgba($theme-dark-border-color, 0.08);
    right: 0;
  }
  .dashboard-toggle {
    background: $primary;
    color: $white !important;
    display: block;
    box-shadow: -3px 0px 8px rgba($theme-dark-border-color, 0.1);
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    position: absolute;
    top: calc(50% - 43px);
    width: 38px;
    height: 38px;
    left: -39px;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
  }
  .dashboard-settings {
    padding: 20px;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
  }
}
</style>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import {
  BLink,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormTextarea,
  BButton,
} from "bootstrap-vue";
import { mapGetters } from "vuex";
import {
  SAVE_DASHBOARD,
  SET_DASHBOARD_CRUD_DESCRIPTION,
  SET_DASHBOARD_CRUD_TITLE,
  TOGGLE_DASHBOARD_STATUS,
} from "@/store/services/dashboard-crud";

export default {
  name: "DashboardSettings",
  components: {
    ValidationObserver,
    ValidationProvider,
    BLink,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormTextarea,
    BButton,
  },
  data() {
    return {
      show: false,
    };
  },
  computed: {
    ...mapGetters([
      "dashboardCrudIsPassive",
      "dashboardCrudTitle",
      "dashboardCrudDescription",
    ]),
    title: {
      get() {
        return this.dashboardCrudTitle;
      },
      set(val) {
        this.$store.commit(SET_DASHBOARD_CRUD_TITLE, val);
      },
    },
    description: {
      get() {
        return this.dashboardCrudDescription;
      },
      set(val) {
        this.$store.commit(SET_DASHBOARD_CRUD_DESCRIPTION, val);
      },
    },
  },
  methods: {
    saveReport() {
      this.$store.dispatch(SAVE_DASHBOARD);
    },
    changeStatus() {
      this.$store.commit(TOGGLE_DASHBOARD_STATUS);
    },
  },
};
</script>
