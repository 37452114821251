<template>
  <!-- #region Raporlar -->
  <div
    class="reports-container"
    :class="{ open: show, 'big-z-index': show }"
  >
    <!-- #region Raporlar Aç/Kapat -->
    <b-link
      class="reports-toggle d-flex align-items-center justify-content-center"
      @click="show = !show"
    >
      <feather-icon
        icon="SettingsIcon"
        size="15"
      />
    </b-link>
    <!-- #endregion -->
    <!-- #region Arama -->
    <b-input-group class="search">
      <b-input-group-prepend
        class="search-input-prepend"
        is-text
      >
        <feather-icon icon="SearchIcon" />
      </b-input-group-prepend>
      <b-form-input
        v-model="search"
        class="search-input"
        type="text"
        :placeholder="$t('dashboard_crud.search')"
      />
    </b-input-group>
    <!-- #endregion -->
    <!-- #region Rapor -->
    <draggable
      :list="findedSearch()"
      :group="{ name: 'reports', pull: 'clone', put: false }"
      class="reports list-group border rounded"
      handle=".drag-button"
      :clone="cloneMethod"
    >
      <b-card
        v-for="item in findedSearch()"
        :key="item.id"
        class="report-card"
      >
        <feather-icon
          v-b-tooltip.hover.bottom="$t('dashboard_crud.drag')"
          class="drag-button"
          width="20px"
          height="20px"
          icon="MoveIcon"
        />
        <i :class="'icon fal fa-' + iconClass(item)" />
        <h3>
          {{ item.name }}
        </h3>
        <span class="desc">
          {{ item.desc }}
        </span>
      </b-card>
    </draggable>
    <!-- #endregion -->
  </div>
  <!-- #endregion -->
</template>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/include";
.search-input-prepend {
  .input-group-text {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
  }
}
.search-input {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.dark-layout {
  .reports-container {
    background: $theme-dark-body-bg;
    border-color: $theme-dark-border-color;
  }
}
.reports-container {
  width: 300px;
  padding: 0;
  z-index: 1051;
  position: fixed;
  top: 0;
  right: -300px;
  height: 100vh;
  background: $body-bg;
  height: calc(var(--vh, 1vh) * 100);
  transition: right 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
  backface-visibility: hidden;
  border-left: 1px solid $border-color;
  &.big-z-index {
    z-index: 1053;
  }
  &.open {
    box-shadow: 0 15px 30px 0 rgba($theme-dark-border-color, 0.11),
      0 5px 15px 0 rgba($theme-dark-border-color, 0.08);
    right: 0;
  }
  .reports-toggle {
    background: $primary;
    color: $white !important;
    display: block;
    box-shadow: -3px 0px 8px rgba($theme-dark-border-color, 0.1);
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    position: absolute;
    top: 50%;
    width: 38px;
    height: 38px;
    left: -39px;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
  }
  .reports {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 14px;
    padding: 14px;
    margin: 0;
    height: 100%;
    overflow-y: scroll;
    border-top: none !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    .report-card {
      margin: 0;
      padding: 0;
      width: 250px;
      .card-body {
        padding: 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 250px;
        .drag-button {
          position: absolute;
          top: 15px;
          left: 15px;
        }
        .icon {
          font-size: 55px;
          margin-bottom: 1rem;
        }
        .desc {
          max-height: 70px;
          width: 100%;
          overflow-y: scroll;
        }
        &:hover,
        &:focus {
          border-color: $theme-dark-border-color !important;
          background: $dropdown-link-hover-bg;
          color: $dropdown-link-hover-color;
          h3 {
            color: $dropdown-link-hover-color;
          }
        }
      }
    }
  }
}
</style>

<script>
import {
  BLink,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BCard,
  VBTooltip,
} from 'bootstrap-vue'
import draggable from 'vuedraggable'
import { mapGetters } from 'vuex'

export default {
  name: 'ReportsForDashboard',
  components: {
    BLink,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BCard,
    draggable,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      show: false,
      icons: [
        { id: 1, icon: 'table' },
        { id: 3, icon: 'chart-pie' },
        { id: 4, icon: 'file-chart-line' },
        { id: 5, icon: 'calendar-alt' },
      ],
      search: '',
    }
  },
  computed: {
    ...mapGetters(['useableReports']),
  },
  methods: {
    iconClass(item) {
      return this.icons.find(x => x.id === item.type).icon
    },
    findedSearch() {
      if (this.search === '') {
        return this.useableReports
      }
      return this.useableReports.filter(
        x => x.name.toLowerCase().includes(this.search.toLowerCase())
          || x.desc?.toLowerCase()?.includes(this.search.toLowerCase()),
      )
    },
    cloneMethod(item) {
      const report = {
        id: item.id,
        column: 6,
        uid: Math.random()
          .toString(16)
          .slice(2),
      }
      return report
    },
  },
}
</script>
