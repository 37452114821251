<template>
  <div ref="bilgeCodeChart" class="chart" />
</template>

<style lang="scss" scoped>
.chart {
  width: 100%;
  min-height: 450px;
}
</style>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themesFrozen from "@amcharts/amcharts4/themes/frozen";
import am4themesDark from "@amcharts/amcharts4/themes/dark";

export default {
  name: "CommonReportChart",
  props: {
    reportMasterId: { required: true, type: [Number, String] },
    chartSettings: { required: true, type: Object },
    chartData: { required: true, type: Array },
  },
  computed: {
    skin() {
      return this.$store.getters["appConfig/getThemeSkin"];
    },
  },
  watch: {
    skin() {
      this.changeTheme();
    },
  },
  mounted() {
    this.build();
    this.$emit("ready");
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  },
  methods: {
    changeTheme() {
      am4core.unuseAllThemes();
      if (this.skin === "light") {
        am4core.useTheme(am4themesFrozen);
      } else {
        am4core.useTheme(am4themesDark);
      }
    },
    build() {
      this.changeTheme();
      let cS = this.chartSettings;
      cS.data = this.chartData;
      let chart = am4core.createFromConfig(
        { ...cS },
        this.$refs.bilgeCodeChart
      );
      this.chart = chart;
    },
  },
};
</script>
