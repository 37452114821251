<template>
  <b-form-input
    v-model="temp.value"
    :placeholder="$t('reportcrud.2.basic_input_placeholder')"
    :type="temp.option"
  />
</template>

<script>
import { BFormInput } from 'bootstrap-vue'

export default {
  name: 'basic-input',
  props: {
    inputObject: {
      required: true,
      type: Object,
    },
  },
  components: {
    BFormInput,
  },
  data() {
    return {
      temp: this.inputObject,
    }
  },
  emits: ['update'],
  watch: {
    temp(val) {
      this.$emit('update', val)
    },
  },
}
</script>