<template>
  <b-form-group :label="temp.name" :label-for="'filter_' + index">
    <basic-input
      :id="'filter_' + index"
      v-if="temp.type == 0"
      :input-object="temp"
      @update="(newVal) => (temp = newVal)"
    />
    <select-input
      :id="'filter_' + index"
      v-if="temp.type == 1"
      :select-object="temp"
      @update="(newVal) => (temp = newVal)"
    />
    <date-input
      :id="'filter_' + index"
      v-if="temp.type == 2 && dateShow"
      :date-object="temp"
      @update="(newVal) => (temp = newVal)"
    />
  </b-form-group>
</template>

<script>
import { BFormGroup } from 'bootstrap-vue'
import BasicInput from './Basic.vue'
import SelectInput from './Select.vue'
import DateInput from './DatePicker.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'dynamic-input',
  props: {
    index: { required: true },
    inputObject: {
      required: true,
      type: Object,
    },
  },
  components: {
    BFormGroup,
    BasicInput,
    SelectInput,
    DateInput,
  },
  data() {
    return {
      temp: this.inputObject,
    }
  },
  computed:{
    ...mapGetters(['dateShow'])
  },
  emits: ['update'],
  watch: {
    temp(val) {
      this.$emit('update', val)
    },
  },
}
</script>