<!-- eslint-disable vue/no-v-html -->
<template>
  <b-row>
    <!-- #region Panel Raporları -->
    <b-col cols="12">
      <b-card class="mb-1">
        <h3 class="mb-0">
          {{ $t("dashboard_crud.preview_title") }}
        </h3>
        <h5
          class="text-muted"
          v-html="$t('dashboard_crud.preview_placeholder')"
        />
      </b-card>
      <draggable
        :list="dashboardCrudReports"
        group="reports"
        class="report-drag row border rounded p-0 py-1 m-0"
        handle=".drag-button"
      >
        <common-report
          v-for="(item, index) in dashboardCrudReports"
          :key="`${item.uid}`"
          :full-heigth="true"
          :report-id="item.id"
          :report-index="index"
          :column-size="item.column ? item.column : 6"
          :delete-enable="true"
          @deleteReport="deleteReport"
        />
      </draggable>
    </b-col>
    <!-- #endregion -->
    <dashboard-settings />
    <reports />
  </b-row>
</template>

<style lang="scss" scoped>
.report-drag {
  min-height: 500px;
  width: 100%;
}
.save-button {
  position: absolute;
  right: 21px;
  bottom: 21px;
}
</style>

<script>
import { BRow, BCol, BCard } from "bootstrap-vue";
import draggable from "vuedraggable";
import { mapGetters } from "vuex";
import CommonReport from "../Report/components/CommonReport.vue";
import DashboardSettings from "./components/DashboardSettings.vue";
import Reports from "./components/Reports.vue";
import {
  DELETE_REPORT,
  GET_DASHBOARD_FOR_UPDATE,
  GET_USEABLE_REPORTS,
  SET_DEFAULT_DASHBOARD,
} from "@/store/services/dashboard-crud";

export default {
  name: "DashboardFormContent",
  components: {
    BRow,
    BCol,
    BCard,
    draggable,
    CommonReport,
    DashboardSettings,
    Reports,
  },
  props: {
    isNew: { required: true, type: Boolean },
  },
  computed: {
    ...mapGetters(["dashboardCrudReports"]),
  },
  created() {
    this.$store.commit(SET_DEFAULT_DASHBOARD);
    if (!this.isNew) {
      this.$store.dispatch(GET_DASHBOARD_FOR_UPDATE, this.$route.params.id);
    }
    this.$store.dispatch(GET_USEABLE_REPORTS);
  },
  beforeDestroy() {
    this.$store.commit(SET_DEFAULT_DASHBOARD);
  },
  methods: {
    deleteReport(index) {
      this.$store.commit(DELETE_REPORT, index);
    },
  },
};
</script>
