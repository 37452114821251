<template>
  <treeselect
    v-model="temp.value"
    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
    :options="selectable"
    :placeholder="$t('reportcrud.2.select_placeholder')"
    :multiple="temp.option == 'true'"
  />
</template>

<script>
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import '@/views/Report/components/scss/treeselect.scss'

export default {
  name: 'select-input',
  props: {
    selectObject: {
      required: true,
      type: Object,
    },
  },
  components: {
    Treeselect,
  },
  data() {
    return {
      temp: this.selectObject,
    }
  },
  computed:{
    selectable(){
      return this.temp.selectOptions.filter(item => item.id != '' && item.label != '')
    }
  },
  emits: ['update'],
  watch: {
    temp(val) {
      this.$emit('update', val)
    },
  },
}
</script>