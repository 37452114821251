<template>
  <div>
    <pivot
      :report="dataSource"
      :global="{ localization: 'https://cdn.webdatarocks.com/loc/tr.json' }"
      toolbar
      :reportchange="reportChange"
      ref="pivotTable"
    ></pivot>
  </div>
</template>

<style lang="scss" scoped>
.chart {
  width: 100%;
  min-height: 400px;
}
</style>

<script>
import Pivot from "./Pivot.vue";

export default {
  components: { Pivot },
  name: "common_report_pivot",
  props: {
    reportMasterId: { required: true, type: [Number, String] },
    pivotSettings: { required: true },
    pivotData: { required: true },
  },
  data() {
    return {
      dataSource: null,
      global: { localization: "https://cdn.webdatarocks.com/loc/tr.json" },
    };
  },
  created() {
    this.dataSource = {
      dataSource: {
        data: this.pivotData,
      },
      slice: this.pivotSettings,
    };
    this.$emit("ready");
  },
  methods: {
    reportChange() {
      let tempDataSource = this.$refs.pivotTable.webdatarocks.getReport();
      let tempSlice = tempDataSource.slice;
      this.$emit("updateSlice", tempSlice);
    },
  },
};
</script>
