<template>
  <b-col cols="12" :md="column" class="mb-1">
    <b-overlay
      :show="!reportReady"
      variant="dark"
      opacity="0.85"
      blur="2px"
      rounded="sm"
    >
      <div class="bg-lighten-5 p-0">
        <div class="card">
          <div
            class="
              card-header
              d-flex
              justify-content-space-beetween
              align-items-start
              pb-1
            "
          >
            <div>
              <h4 class="card-title mb-25">
                {{ reportName }}
              </h4>
              <p class="card-text">
                {{ description }}
              </p>
            </div>
            <div class="report-options">
              <div v-if="!fullHeigth">
                <button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-tooltip.hover.bottom.v-primary
                  :title="$t('report_display.drag')"
                  class="btn btn-sm drag-button"
                >
                  <i class="fal fa-arrows text-primary" />
                </button>
              </div>
              <div v-if="reportFault == null" class="update-button">
                <button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-tooltip.hover.bottom.v-primary
                  v-b-modal="'modal-filter' + reportMasterId + reportIndex"
                  :title="$t('report_display.filters')"
                  class="btn btn-sm"
                >
                  <i class="fal fa-filter text-primary" />
                </button>
              </div>
              <div class="update-button">
                <button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-tooltip.hover.bottom.v-primary
                  :title="$t('report_display.edit')"
                  class="btn btn-sm"
                  @click="goToUpdateReport()"
                >
                  <i class="fal fa-edit text-primary" />
                </button>
              </div>
              <div class="refresh-button">
                <button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-tooltip.hover.bottom.v-primary
                  :title="$t('report_display.refresh')"
                  class="btn btn-sm"
                  @click="generateReport()"
                >
                  <i class="fal fa-sync-alt text-primary" />
                </button>
              </div>
              <div class="size-toggle">
                <button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-tooltip.hover.bottom.v-primary
                  :title="
                    column == 6
                      ? $t('report_display.bigger')
                      : $t('report_display.smaller')
                  "
                  class="btn btn-sm"
                  @click="changeColumnSize()"
                >
                  <feather-icon
                    height="1.3rem"
                    width="1.3rem"
                    class="text-primary"
                    :icon="column == 6 ? 'MaximizeIcon' : 'MinimizeIcon'"
                  />
                </button>
              </div>
              <div v-if="deleteEnable" class="delete-report">
                <button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-tooltip.hover.bottom.v-primary
                  :title="$t('report_display.delete')"
                  class="btn btn-sm"
                  @click="deleteReport()"
                >
                  <i class="fal fa-trash-alt text-primary" />
                </button>
              </div>
            </div>
          </div>
          <div :class="`card-body ${fullHeigthClass}`">
            <div v-if="reportReady">
              <common-report-table
                v-if="reportTypeId == 1"
                ref="table"
                :table-settings="settings"
                :table-data="reportData"
                :report-name="reportName"
                :report-master-id="reportMasterId"
                :index="reportIndex"
              />
              <common-report-chart
                v-else-if="reportTypeId == 3"
                ref="chart"
                :chart-settings="settings"
                :chart-data="reportData"
                :report-master-id="reportMasterId"
              />
              <common-report-pivot
                v-else-if="reportTypeId == 4"
                ref="pivot"
                :pivot-settings="settings"
                :pivot-data="reportData"
                :report-master-id="reportMasterId"
              />
              <common-report-calendar
                v-else-if="reportTypeId == 5"
                ref="calendar"
                :calendar-settings="settings"
                :calendar-data="reportData"
                :report-master-id="reportMasterId"
              />
              <div v-else-if="reportFault != null" class="report-fault">
                <b-alert class="alert-box" variant="danger" show>
                  <feather-icon class="alert-icon" icon="AlertTriangleIcon" />
                  <p class="text-center">
                    {{ reportFault.message }}
                  </p>
                </b-alert>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
    <b-modal
      :id="'modal-filter' + reportMasterId + reportIndex"
      hide-footer
      centered
      size="lg"
      :title="reportName + $t('report_display.filters')"
    >
      <b-row>
        <b-col cols="12">
          <h2 class="d-flex justify-content-between">
            {{ $t("report_display.filters") }}
          </h2>
          <b-row class="filters border border-primary rounded mt-1">
            <b-col v-if="reportReady" cols="12">
              <b-row
                v-for="(item, index) in replaceableFilters"
                :key="index"
                class="filter"
              >
                <b-col :cols="item.colSize">
                  <dynamic-input
                    :index="reportName + index + reportIndex"
                    :input-object="item"
                  />
                </b-col>
              </b-row>
              <b-row v-if="replaceableFilters.length == 0">
                <span>{{ $t("report_display.no_filter_placeholder") }}</span>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="filter-footer">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="success"
          @click="updateReport()"
        >
          {{ $t("report_display.update_data") }}
        </b-button>
      </b-row>
    </b-modal>
  </b-col>
</template>

<style lang="scss" scoped>
.card-body {
  min-height: 500px;
  max-height: 1000px;
  overflow: scroll;
}
.scroll-heigth {
  max-height: 500px;
}
.report-options {
  display: flex;
}
.fa-filter,
.fa-edit,
.fa-sync-alt,
.fa-arrows,
.fa-trash-alt {
  font-size: 1.25rem;
}
.report-fault {
  height: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
  .alert-box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 15px;
    .alert-icon {
      height: 35px;
      width: 35px;
      margin-bottom: 1rem;
    }
  }
}
.filters {
  padding: 1rem;
  margin: 0.5rem 0;
  display: flex;
  gap: 10px;
  .filter {
    display: flex;
    border: none;
  }
}
.filter-footer {
  display: flex;
  padding: 5px 10px;
  margin-top: 1rem;
  justify-content: flex-end;
}
</style>

<script>
import {
  BButton,
  VBTooltip,
  BOverlay,
  BAlert,
  BModal,
  BRow,
  BCol,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

import {
  GET_REPORT_FILTERS,
  GET_REPORT_WITH_DATA,
} from "@/store/services/report-service";

import CommonReportTable from "./CommonReportTable.vue";
import CommonReportChart from "./CommonReportChart.vue";
import CommonReportPivot from "./CommonReportPivot.vue";
import CommonReportCalendar from "./CommonReportCalendar.vue";
import DynamicInput from "./input/DynamicInput.vue";
import { replaceSQLVariable } from "./js/replaceSQLVariable";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import { UPDATE_COLUMN } from "@/store/services/dashboard-crud";

export default {
  name: "CommonReport",
  components: {
    BButton,
    BOverlay,
    BAlert,
    BModal,
    BRow,
    BCol,
    CommonReportTable,
    CommonReportChart,
    CommonReportPivot,
    CommonReportCalendar,
    DynamicInput,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  props: {
    reportId: { required: true, type: [Number, String] },
    reportIndex: { required: false, default: 1, type: Number },
    columnSize: { required: true, type: [Number, String] },
    fullHeigth: { default: false, type: Boolean },
    deleteEnable: { default: false, type: Boolean },
  },
  data() {
    return {
      reportData: null,
      reportMasterId: null,
      reportName: null,
      reportGroupId: null,
      description: null,
      filters: null,
      attach_variables: null,
      query: null,
      column: this.columnSize,
      reportTypeId: null,
      reportTypeName: null,
      settings: null,
      reportReady: false,
      reportFault: null,
      processFilters: null,
      replaceableFilters: [],
    };
  },
  computed: {
    fullHeigthClass() {
      const className = this.fullHeigth ? "" : "scroll-heigth";
      return className;
    },
  },
  created() {
    this.$store
      .dispatch(GET_REPORT_FILTERS, this.reportId)
      .then((response) => {
        this.reportFault = null;
        this.processFilters = response;
        this.generateReport(true);
      })
      .catch((err) => {
        this.reportFault = err;
        this.reportReady = true;
      });
  },
  methods: {
    generateReport(firstAttach = false) {
      return new Promise((resolve) => {
        this.reportReady = false;
        const reportMaster = {
          report_m_id: this.reportId,
          filters: this.processFilters,
        };
        this.$store
          .dispatch(GET_REPORT_WITH_DATA, reportMaster)
          .then((response) => {
            this.reportFault = null;
            this.reportData = response.data;
            if (firstAttach) {
              this.reportMasterId = response.report_master_id;
              this.reportName = response.report_name;
              this.reportTypeId = response.report_type_id;
              this.reportTypeName = response.report_type_name;
              this.query = response.sql_query;
              this.description = response.description;
              this.settings = JSON.stringify(response.settings_json);
              this.replaceableFilters = response.filter_json.filters;
              this.filters = response.filter_json.filters;
              this.attach_variables = response.filter_json.attach_variables;
              this.attach_variables.forEach((item) => {
                this.settings = this.settings.replaceAll(
                  `#${item.name}#`,
                  item.value
                );
              });
              this.settings = JSON.parse(this.settings);
            }
          })
          .then(() => {
            this.reportReady = true;
            resolve();
          })
          .catch((err) => {
            this.reportReady = true;
            this.reportFault = err;
          });
      });
    },
    changeColumnSize() {
      if (this.column < 12) {
        this.column = 12;
      } else {
        this.column = 6;
      }
      if (this.deleteEnable) {
        const value = this.column;
        this.$store.commit(UPDATE_COLUMN, { index: this.reportIndex, value });
      }
      this.generateReport();
    },
    goToUpdateReport() {
      const routeData = this.$router.resolve({
        name: "/Report/Edit",
        params: { id: this.$props.reportId },
      });
      window.open(routeData.href, "_blank");
    },
    updateReport() {
      this.processFilters = [];
      this.filters.forEach((item) => {
        this.processFilters.push({
          text: `{${item.SQLVariable}}`,
          value: replaceSQLVariable(item),
        });
      });
      this.generateReport();
      this.$bvModal.hide(`modal-filter${this.reportMasterId}`);
      this.$toast(
        {
          component: ToastificationContentVue,
          props: {
            title: "Veri Güncelleme",
            icon: "CheckCircleIcon",
            variant: "success",
            text: "Veri Güncellendi!",
          },
        },
        {
          position: "bottom-left",
        }
      );
    },
    deleteReport() {
      this.$emit("deleteReport", this.reportIndex);
    },
    replaceSQLVariable,
  },
};
</script>
