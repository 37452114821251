<template>
  <table :id="`bilgeCode_report_${reportMasterId}_${index}`" class="table"></table>
</template>

<style lang="scss" scoped></style>

<script>
import '@/assets/js/functions/datatables.settings'
import $ from 'jquery'

export default {
  name: 'common_report_table',
  props: {
    reportName: { required: true, type: [Number, String] },
    reportMasterId: { required: true },
    index: { required: false, default: 1 },
    tableSettings: { required: true },
    tableData: { required: true }
  },
  data() {
    return {
      table: null
    }
  },
  mounted() {
    new Promise(resolve => {
      this.tableSettings.forEach(item => {
        item.render = eval(`(${item.code})`)
      })
      resolve()
    })
      .then(() => {
        this.table = $(`#bilgeCode_report_${this.reportMasterId}_${this.index}`).DataTable({
          columns: this.tableSettings,
          data: this.tableData,
          scrollX: true,
          scrollY: '1000px',
          scrollCollapse: true,
          buttons: [
            {
              extend: 'colvis',
              text: 'Göster&Gizle'
            },
            {
              extend: 'pdf'
            },
            {
              extend: 'excel',
              title: `${this.reportName} Raporu`,
              filename: `${this.reportName} Raporu`
            }
          ]
        })
      })
      .then(() => {
        this.$emit('ready')
      })
  }
}
</script>
